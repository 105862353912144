import React from "react";
import theme from "theme";
import { Theme } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				Railways Adventur
			</title>
			<meta name={"description"} content={"Master the Art of Train Management"} />
			<meta property={"og:title"} content={"Railways Adventur"} />
			<meta property={"og:description"} content={"Master the Art of Train Management"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6724b04a41a0cf0024a526df/images/images.png?v=2024-11-01T12:14:09.112Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6724b04a41a0cf0024a526df/images/images.png?v=2024-11-01T12:14:09.112Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6724b04a41a0cf0024a526df/images/images.png?v=2024-11-01T12:14:09.112Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6724b04a41a0cf0024a526df/images/images.png?v=2024-11-01T12:14:09.112Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6724b04a41a0cf0024a526df/images/images.png?v=2024-11-01T12:14:09.112Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6724b04a41a0cf0024a526df/images/images.png?v=2024-11-01T12:14:09.112Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6724b04a41a0cf0024a526df/images/images.png?v=2024-11-01T12:14:09.112Z"} />
		</Helmet>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66fbc4301b94b10024315de0"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n  scroll-behavior: smooth;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});